.div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
}

label {
  width: 75%;
}

input, select {
  font-size: 20px;
  height: 50px;
  width: 100%;
  margin: 10px 0;
}

button{
  width: 50%;
  padding: 1rem;
  font-size: 2rem;
  margin: 2rem;
}

h2{
  padding-top: 25px;
  text-align: center;
  margin-bottom: -25px;
}

.wrapperClassName{
    margin: 1rem 0;
    border: 2px solid gray;
}

.loading-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}